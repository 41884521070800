import { getPageVariation } from '../../core/analytics/shared.analytics'
import { sendUtagEvent } from '../../core/analytics/utag_events'
import { currentDevice } from '../../core/utils/currentDevice'
import on from '../../utils/events_delegation'
import { getCleanedString, search_action_type } from '../../utils/utilities'

export function isDestiniaForm() {
  const destinaTab = document.querySelector('.js-destinia-tab')
  return destinaTab === null ? false : destinaTab.classList.contains('active')
}

export function isFhiPage() {
  return document.querySelector('html').dataset.fhiTest !== undefined
}
export function isFhPage() {
  return document.querySelector('html').dataset.pageTyoe === 'hotel'
}

const isFastbookingSticky = () => Boolean(document.querySelector('.utag-fastbooking')?.classList.contains('sticky'))

export function initializeFastbookingTabUtagEvent() {
  /* utag-event for flight and hotel tab */
  document.querySelector('.utag-flight-hotel-tab')?.addEventListener(
    'click',
    function () {
      sendUtagEvent({
        data: {
          event_name: 'searcher_select',
          event_cat: 'searcher',
          event_act: 'flight and hotel',
          event_lbl: '',
          event_purpose: 'booking',
          event_structure: isFastbookingSticky() ? 'sticky' : 'body',
        },
      })
    },
    { once: true }
  )

  /* utag-event for hotel tab */
  document.querySelector('.utag-hotel-tab')?.addEventListener(
    'click',
    function () {
      sendUtagEvent({
        data: {
          event_name: 'searcher_select',
          event_cat: 'searcher',
          event_act: 'hotel',
          event_lbl: '',
          event_purpose: 'booking',
          event_structure: isFastbookingSticky() ? 'sticky' : 'body',
        },
      })
    },
    { once: true }
  )
}

export function initializeFastbookingDatesUtagEvent() {
  IB.VisitorSessionId.initNewVisitorSessionId()
  const event_name = isDestiniaForm() ? 'searcher_flight_hotel' : 'searcher_hotel'
  const event_cat = isDestiniaForm() ? 'searcher fly' : 'searcher'
  const event_act = 'dates'
  const event_purpose = 'booking'
  const event_structure = 'body'
  const page_variation = getPageVariation()
  let event_lbl = ''

  const datepicker = document.querySelector('.utag-dates-event .utag-dates-container')

  const dateCheckIn = datepicker?.querySelector('.utag-check-in')?.value
  const dateCheckOut = datepicker?.querySelector('.utag-check-out')?.value

  event_lbl = `${dateCheckIn}-${dateCheckOut}`

  sendUtagEvent({
    data: {
      event_name,
      event_cat,
      event_act,
      event_lbl,
      event_purpose,
      event_structure,
      page_variation,
    },
  })
}

export function initializeUtagEvents() {
  let event_name, event_cat, event_act, event_lbl, event_purpose, event_structure, page_variation
  const resource_class = document.querySelector('body').dataset.utagResourceClass
  const fhiPageType = document.querySelector('html').dataset.fhiTest

  /* Destination (place to go) input events */
  document.querySelector('.utag-fake-fb-click')?.addEventListener(
    'click',
    () => {
      event_name = isDestiniaForm() ? 'searcher_flight_hotel' : 'searcher_hotel'
      event_cat = isDestiniaForm() ? 'searcher fly' : 'searcher'
      event_act = 'destination'
      event_lbl = ''
      event_purpose = 'booking'
      event_structure = 'body'
      page_variation = getPageVariation()
      sendUtagEvent({
        data: {
          event_name,
          event_cat,
          event_act,
          event_lbl,
          event_purpose,
          event_structure,
          page_variation,
        },
      })
    },
    { once: true }
  )

  /* Airports (destinia) input events */

  document.querySelector('.utag-departure-airport')?.addEventListener('click', () => {
    event_name = 'searcher_flight_hotel'
    event_cat = 'searcher fly'
    event_act = 'origin'
    event_lbl = ''
    event_purpose = 'booking'
    event_structure = 'body'
    page_variation = getPageVariation()
    sendUtagEvent({
      data: {
        event_name,
        event_cat,
        event_act,
        event_lbl,
        event_purpose,
        event_structure,
        page_variation,
      },
    })
  })

  /* People input events */
  document.querySelector('.utag-people')?.addEventListener(
    'click',
    function () {
      const event_name = isDestiniaForm() ? 'searcher_flight_hotel' : 'searcher_hotel'
      const event_cat = isDestiniaForm() ? 'searcher fly' : 'searcher'
      const event_purpose = 'booking'
      const event_structure = isFastbookingSticky() ? 'sticky' : 'body'

      let numberOfPeople = ''
      let numberOfRooms = ''
      const peopleContainer = document.querySelector('.utag-people')
      if (currentDevice.isMobile) {
        const numberOfAdults = peopleContainer?.querySelector('.utag-room-adults')?.innerHTML.split(' ')[0]
        const numberOfChildren = peopleContainer?.querySelector('.utag-room-children')?.innerHTML.split(' ')[0]
        numberOfPeople = parseInt(numberOfAdults, 10) + parseInt(numberOfChildren, 10)
        numberOfRooms = document.querySelector('.utag-room-count')?.value
      } else {
        numberOfPeople = peopleContainer?.querySelector('.utag-result-number-of-guest')?.innerHTML?.replace(/\s/g, '')
        numberOfRooms = peopleContainer?.querySelector('.utag-result-number-of-rooms')?.innerHTML?.replace(/\s/g, '')
      }

      sendUtagEvent({
        data: {
          event_name,
          event_cat,
          event_act: 'rooms',
          event_lbl: '',
          event_purpose,
          event_structure,
        },
      })
      sendUtagEvent({
        data: {
          event_name,
          event_cat,
          event_act: 'guests',
          event_lbl: '',
          event_purpose,
          event_structure,
        },
      })
    },
    { once: true }
  )

  /* Confirm people and rooms button events */
  document.querySelector('.utag-set-people-room-button')?.addEventListener(
    'click',
    () => {
      const rooms_info = IB.fastbooking_state.getField('rooms_info')
      const num_rooms = Object.keys(rooms_info).length
      let num_adults = 0
      let num_children = 0
      let i
      for (i = 0; i < Object.keys(rooms_info).length; i++) {
        num_adults += rooms_info[i].adults_count
        num_children += rooms_info[i].children_count
      }
      event_name = isDestiniaForm() ? 'searcher_flight_hotel' : 'searcher_hotel'
      event_cat = isDestiniaForm() ? 'searcher fly' : 'searcher'
      event_act = 'guests'
      event_lbl = ``
      event_purpose = 'booking'
      event_structure = 'body'
      page_variation = getPageVariation()
      sendUtagEvent({
        data: {
          event_name,
          event_cat,
          event_act,
          event_lbl,
          event_purpose,
          event_structure,
          page_variation,
        },
      })
    },
    { once: true }
  )

  /* Calendar events */
  document.querySelector('.utag-dates-container')?.addEventListener(
    'click',
    () => {
      event_name = isDestiniaForm() ? 'searcher_flight_hotel' : 'searcher_hotel'
      event_cat = isDestiniaForm() ? 'searcher fly' : 'searcher'
      event_act = 'dates'
      event_purpose = 'booking'
      event_structure = 'body'
      page_variation = getPageVariation()
      event_lbl = ''
      sendUtagEvent({
        data: {
          event_name,
          event_cat,
          event_act,
          event_lbl,
          event_purpose,
          event_structure,
          page_variation,
        },
      })
    },
    { once: true }
  )

  document.querySelector('.utag-fake-calendar')?.addEventListener(
    'click',
    () => {
      event_name = isDestiniaForm() ? 'searcher_flight_hotel' : 'searcher_hotel'
      event_cat = isDestiniaForm() ? 'searcher fly' : 'searcher'
      event_act = 'flexible date'
      event_lbl = 'see calendar'
      event_structure = 'body'
      event_purpose = 'booking'
      page_variation = getPageVariation()
      sendUtagEvent({
        data: {
          event_name,
          event_cat,
          event_act,
          event_lbl,
          event_purpose,
          event_structure,
          page_variation,
        },
      })
    },
    { once: true }
  )

  document.querySelectorAll('.utag-exit-without-dates').forEach(
    exitWithOut => {
      exitWithOut.addEventListener('click', () => {
        event_name = isDestiniaForm() ? 'searcher_flight_hotel' : 'searcher_hotel'
        event_cat = isDestiniaForm() ? 'searcher fly' : 'searcher'
        event_act = 'dates'
        event_purpose = 'booking'
        event_structure = 'body'
        page_variation = getPageVariation()
        event_lbl = 'without dates'
        sendUtagEvent({
          data: {
            event_name,
            event_cat,
            event_act,
            event_lbl,
            event_purpose,
            event_structure,
            page_variation,
          },
        })
      })
    },
    { once: true }
  )

  /* Discounts events */
  document.querySelector('.utag-fastbooking .utag-promo-input')?.addEventListener(
    'click',
    () => {
      event_name = isDestiniaForm() ? 'searcher_flight_hotel' : 'searcher_hotel'
      event_cat = isDestiniaForm() ? 'searcher fly' : 'searcher'
      event_act = ''
      event_purpose = 'booking'
      event_lbl = ''
      event_structure = 'body'
      page_variation = getPageVariation()
      sendUtagEvent({
        data: {
          event_name,
          event_cat,
          event_act: 'discount',
          event_lbl,
          event_purpose,
          event_structure,
          page_variation,
        },
      })
      sendUtagEvent({
        data: {
          event_name,
          event_cat,
          event_act: 'dropdown discounts',
          event_lbl,
          event_purpose,
          event_structure,
          page_variation,
        },
      })
    },
    { once: true }
  )

  document.querySelector('.utag-discount-popover .utag-promocode-value')?.addEventListener(
    'focus',
    e => {
      event_name = isDestiniaForm() ? 'searcher_flight_hotel' : 'searcher_hotel'
      event_cat = isDestiniaForm() ? 'searcher fly' : 'searcher'
      event_act = 'promotion code'
      event_purpose = 'booking'
      event_lbl = ''
      event_structure = 'body'
      page_variation = getPageVariation()

      event_lbl = e.target.dataset.valueInput

      sendUtagEvent({
        data: {
          event_name,
          event_cat,
          event_act: 'promotion code',
          event_lbl: '',
          event_purpose: 'booking',
          event_structure: 'body',
          page_variation,
        },
      })
    },
    { once: true }
  )

  on({
    eventName: 'click',
    selector: `.utag-discount input`,
    handler() {
      const element = this

      event_name = isDestiniaForm() ? 'searcher_flight_hotel' : 'searcher_hotel'
      event_cat = resource_class === undefined || resource_class === '' ? 'searcher' : resource_class
      event_act = 'see discount'
      event_purpose = 'booking'
      event_lbl = element.closest('.utag-discount').dataset.discount.startsWith('resident-')
        ? 'discount for residents'
        : ''
      event_structure = 'body'
      page_variation = getPageVariation()

      sendUtagEvent({
        data: {
          event_name,
          event_cat,
          event_act,
          event_lbl: getCleanedString(event_lbl),
          event_purpose,
          event_structure,
          page_variation,
        },
      })
    },
    once: true,
  })

  /*Events for the i link of each resident dicount check box*/
  document
    .querySelectorAll(
      '.utag-discount .info, .utag-discount .info-link, .utag-promo-input .utag-info-promocode, .utag-discount-popover .utag-info-promocode'
    )
    .forEach(element => {
      element.addEventListener('click', event => {
        event_name = 'offers'
        event_cat = resource_class === undefined || resource_class === '' ? 'offers' : resource_class
        event_act = 'see promo code info'
        event_purpose = 'information'
        event_lbl = getCleanedString(
          event.target.closest('.utag-discount')?.dataset.discount.startsWith('resident-')
            ? 'discount for residents'
            : ''
        )
        event_structure = 'popup'
        page_variation = getPageVariation()
        sendUtagEvent({
          data: {
            event_name,
            event_cat,
            event_act,
            event_lbl,
            event_purpose,
            event_structure,
            page_variation,
          },
        })
      })
    })

  /* Scroll to fastbooking events */
  document
    .querySelectorAll(
      '.utag-mobile-fastbooking-bar .utag-open-mobile-panel, .utag-mobile-fastbooking-bar .utag-open-search-btn'
    )
    .forEach(element => {
      element.addEventListener(
        'click',
        () => {
          event_name = isDestiniaForm() ? 'searcher_flight_hotel' : 'searcher_hotel'
          event_cat = isDestiniaForm() ? 'searcher fly' : 'searcher'
          event_act = 'scroll to fastbooking'
          event_purpose = 'booking'
          event_lbl = 'sticky'
          event_structure = 'sticky'
          page_variation = getPageVariation()
          sendUtagEvent({
            data: {
              event_name,
              event_cat,
              event_act,
              event_lbl,
              event_purpose,
              event_structure,
            },
          })
        },
        { once: true }
      )
    })

  document.querySelectorAll('.utag-button-scroll-to').forEach(element => {
    element.addEventListener(
      'click',
      () => {
        event_name = isDestiniaForm() ? 'searcher_flight_hotel' : 'searcher_hotel'
        event_cat = isDestiniaForm() ? 'searcher fly' : 'searcher'
        event_act = 'scroll to fastbooking'
        event_purpose = 'boooking'
        event_lbl = 'body'
        event_structure = 'body'
        page_variation = getPageVariation()
        sendUtagEvent({
          data: {
            event_name,
            event_cat,
            event_act,
            event_lbl,
            event_purpose,
            event_structure,
          },
        })
      },
      { once: true }
    )
  })

  document.querySelector('.utag-fastbooking-gallery')?.addEventListener(
    'click',
    () => {
      event_name = isDestiniaForm() ? 'searcher_flight_hotel' : 'searcher_hotel'
      event_cat = isDestiniaForm() ? 'searcher fly' : 'searcher'
      event_act = 'scroll to fastbooking'
      event_purpose = 'booking'
      event_lbl = 'popup'
      event_structure = 'popup'
      page_variation = getPageVariation()
      sendUtagEvent({
        data: {
          event_name,
          event_cat,
          event_act,
          event_lbl,
          event_purpose,
          event_structure,
          page_variation,
        },
      })
    },
    { once: true }
  )

  document.querySelector('.utag-floating-fastbooking')?.addEventListener(
    'click',
    () => {
      event_name = isDestiniaForm() ? 'searcher_flight_hotel' : 'searcher_hotel'
      event_cat = isDestiniaForm() ? 'searcher fly' : 'searcher'
      event_act = 'scroll to fastbooking'
      event_purpose = 'booking'
      event_lbl = 'popup'
      event_structure = 'popup'
      page_variation = getPageVariation()
      sendUtagEvent({
        data: {
          event_name,
          event_cat,
          event_act,
          event_lbl,
          event_purpose,
          event_structure,
          page_variation,
        },
      })
    },
    { once: true }
  )

  /** Submit */

  document.querySelectorAll('.utag-fastbooking').forEach(element => {
    element.addEventListener(
      'submit',
      e => {
        const fbForm = e.target
        event_name = isDestiniaForm() ? 'searcher_flight_hotel_submit' : 'searcher_hotel_submit'
        event_cat = isDestiniaForm() ? 'searcher fly' : 'searcher'
        event_act = 'submit'
        event_purpose = 'booking'
        event_structure = 'body'

        // Build event label
        event_lbl = ''

        const datepicker = fbForm.querySelector('.utag-dates-event .utag-dates-container')
        const dateCheckIn = datepicker?.querySelector('.utag-check-in')?.value.replace(/\//g, '-')
        const dateCheckOut = datepicker?.querySelector('.utag-check-out')?.value.replace(/\//g, '-')

        let numberOfPeople = ''
        let numberOfRooms = ''
        const peopleContainer = fbForm.querySelector('.utag-people')
        if (currentDevice.isMobile) {
          const numberOfAdults = peopleContainer?.querySelector('.utag-room-adults')?.innerHTML.split(' ')[0]
          const numberOfChildren = peopleContainer?.querySelector('.utag-room-children')?.innerHTML.split(' ')[0]
          numberOfPeople = parseInt(numberOfAdults, 10) + parseInt(numberOfChildren, 10)
          numberOfRooms = document.querySelector('.utag-room-count')?.value
        } else {
          numberOfPeople = peopleContainer?.querySelector('.utag-result-number-of-guest')?.innerHTML.replace(/\s/g, '')
          numberOfRooms = peopleContainer?.querySelector('.utag-result-number-of-rooms')?.innerHTML.replace(/\s/g, '')
        }

        const placeToGoName = `${getCleanedString(fbForm.querySelector('.utag-place-to-go-input')?.innerHTML)}`
        const placeOrigin = `${getCleanedString(fbForm.querySelector('.utag-airport-name')?.value)}`
        const hotelSelected = fbForm.querySelector('.utag-place-to-go-input')
        const hotelSelectedDestination = hotelSelected?.dataset.hotelDestination
        const hotelSelectedId = hotelSelected?.dataset.hotelId
        const datesOfCheckInOut = `${dateCheckIn} ${dateCheckOut}`

        const roomsAndPeople = `${numberOfRooms}_${numberOfPeople}`

        const promocodeInput = fbForm.querySelector('.utag-promo-input .utag-promocode-value')
        const promocode = `${getCleanedString(promocodeInput?.dataset.valueInput)}`

        if (placeToGoName) {
          event_lbl += `${placeToGoName}_`
        }

        if (hotelSelectedDestination && hotelSelectedId.charAt(0) === 'h') {
          event_lbl += `${hotelSelectedDestination}_`
        }

        if (placeOrigin && isDestiniaForm()) {
          event_lbl += `${placeOrigin}_`
        }

        if (dateCheckOut && dateCheckIn) {
          event_lbl += `${datesOfCheckInOut}_`
        }

        if (roomsAndPeople) {
          event_lbl += `${roomsAndPeople}_`
        }

        if (promocode) {
          event_lbl += `${promocode}`
        }

        // Eliminar el último guión bajo "_" si existe
        event_lbl = event_lbl.replace(/_$/, '')

        sendUtagEvent({
          data: {
            event_name,
            event_cat,
            event_act,
            event_lbl,
            event_purpose,
            event_structure,
            ...(isFhiPage ? { page_variation: fhiPageType } : {}),
          },
        })
      },
      { once: true }
    )
  })

  document.querySelector('.utag-fastbooking-gallery')?.addEventListener(
    'click',
    () => {
      event_name = isDestiniaForm() ? 'searcher_flight_hotel' : 'searcher_hotel'
      event_cat = isDestiniaForm() ? 'searcher fly' : 'searcher'
      event_act = 'scroll to fastbooking'
      event_purpose = 'booking'
      event_lbl = 'popup'
      event_structure = 'popup'
      page_variation = getPageVariation()
      sendUtagEvent({
        data: {
          event_name,
          event_cat,
          event_act,
          event_lbl,
          event_purpose,
          event_structure,
          page_variation,
        },
      })
    },
    { once: true }
  )

  document.querySelector('.utag-floating-fastbooking')?.addEventListener(
    'click',
    () => {
      event_name = isDestiniaForm() ? 'searcher_flight_hotel' : 'searcher_hotel'
      event_cat = isDestiniaForm() ? 'searcher fly' : 'searcher'
      event_act = 'scroll to fastbooking'
      event_purpose = 'booking'
      event_lbl = 'popup'
      event_structure = 'popup'
      page_variation = getPageVariation()
      sendUtagEvent({
        data: {
          event_name,
          event_cat,
          event_act,
          event_lbl,
          event_purpose,
          event_structure,
          page_variation,
        },
      })
    },
    { once: true }
  )

  /** Submit */

  document.querySelectorAll('.utag-fastbooking').forEach(
    element => {
      element.addEventListener('submit', () => {
        event_name = isDestiniaForm() ? 'searcher_flight_hotel_submit' : 'searcher_hotel_submit'
        event_cat = isDestiniaForm() ? 'searcher fly' : 'searcher'
        event_act = 'submit'
        event_lbl = 'search'
        event_purpose = 'booking'
        event_structure = 'body'
        sendUtagEvent({
          data: {
            event_name,
            event_cat,
            event_act,
            event_lbl,
            event_purpose,
            event_structure,
            ...(isFhiPage ? { page_variation: fhiPageType } : {}),
          },
        })
      })
    },
    { once: true }
  )

  // TODO: PY0503 quitar JQuery

  /* not in the bible */

  /** Estas donte estan? MICE? */
  $(document).one('click', '.fastbooking-links a.see-more-hotels-fancy', function () {
    event_name = 'searcher_hotel'
    event_cat = 'searcher'
    event_act = 'see hotel list'
    event_lbl = ''
    event_purpose = 'booking'
    event_structure = 'body'
    page_variation = getPageVariation()
    sendUtagEvent({
      data: {
        event_name,
        event_cat,
        event_act,
        event_lbl,
        event_purpose,
        event_structure,
        page_variation,
      },
    })
  })

  document.querySelector('.utag-three-more-rooms').addEventListener('click', function () {
    event_name = 'searcher_hotel'
    event_cat = 'searcher'
    event_act = 'more than 3 rooms'
    event_lbl = ''
    event_purpose = 'booking'
    event_structure = 'body'
    page_variation = getPageVariation()
    sendUtagEvent({
      data: {
        event_name,
        event_cat,
        event_act,
        event_lbl,
        event_purpose,
        event_structure,
        page_variation,
      },
    })
  })

  /* Evento para el selector múltiple del FB en MICE y meetings*/
  let mice_place_value = [] // esto no se para que es
  $(document).on('click', '.place-to-go-mice:visible', function () {
    mice_place_value = $('#vo_meetings_search_destination_id').val()
  })

  $(document).one('change', '#vo_meetings_search_destination_id', function () {
    const place_value = $.merge(
      $(mice_place_value).not($(this).val()).get(),
      $($(this).val()).not(mice_place_value).get()
    ).slice(-1)[0]
    event_name = 'searcher_hotel'
    event_cat = 'searcher'
    event_act = search_action_type(place_value)
    event_lbl = ''
    event_purpose = 'booking'
    event_structure = 'body'
    page_variation = getPageVariation()
    sendUtagEvent({
      data: {
        event_name,
        event_cat,
        event_act,
        event_lbl,
        event_purpose,
        event_structure,
        page_variation,
      },
    })
  })

  $(document).one('selector_change', '.place-to-go-mice .counter', function () {
    const place_value = $('.place-to-go-mice .chosen-choices .search-choice span').first().text()
    event_name = 'searcher_hotel'
    event_cat = 'searcher'
    event_act = search_action_type(place_value)
    event_lbl = ''
    event_purpose = 'booking'
    event_structure = 'body'
    page_variation = getPageVariation()
    sendUtagEvent({
      data: {
        event_name,
        event_cat,
        event_act,
        event_lbl,
        event_purpose,
        event_structure,
        page_variation,
      },
    })
  })

  // Evento para el warning de Vuelo + Hotel
  on({
    eventName: 'click',
    selector: `.js-accept-button`,
    handler() {
      event_name = 'searcher_select'
      event_cat = 'searcher'
      event_act = 'flight and hotel'
      event_lbl = 'confirmation'
      event_structure = 'popup'
      event_purpose = 'booking'

      sendUtagEvent({
        data: {
          event_name,
          event_cat,
          event_act,
          event_lbl,
          event_structure,
          event_purpose,
        },
      })
    },
  })

  on({
    eventName: 'click',
    selector: `.js-reject-button`,
    handler() {
      event_name = 'searcher_select'
      event_cat = 'searcher'
      event_act = 'flight and hotel'
      event_lbl = 'disagreement'
      event_structure = 'popup'
      event_purpose = 'booking'

      sendUtagEvent({
        data: {
          event_name,
          event_cat,
          event_act,
          event_lbl,
          event_structure,
          event_purpose,
        },
      })
    },
  })
}
